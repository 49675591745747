
.fc-dayGridMonth-view .Scheduled{
    background-color:  #ff5f2a1e;
    color : #FF5E2A;
    padding: 8px 10px 8px 10px;   
}
.fc .fc-button-primary{
    background-color: #26BAFF;
    border-color : #26BAFF;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:hover{
    background-color: #052F5D;
    border-color : #052F5D;
}

.fc-dayGridMonth-view .fc-daygrid-event-dot{
    display: none;
}
.fc-dayGridMonth-view .Scheduled .fc-event-title,
.fc-dayGridMonth-view .Walk-In .fc-event-title{
    font-weight: 400!important;
}

.fc-dayGridMonth-view .Walk-In{
    background-color:  #2D33C01e;
    color : #2D33C0;
    padding: 8px 10px 8px 10px;
}

.fc-media-screen {
cursor: pointer;
}
.custom-tooltip {
    position: absolute;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    transform: translateY(-100%);
    z-index: 9999;
  }

